<template>
	<app-toolbar>
		<div class="left">
			<!-- Main action buttons -->
			<tool-bar-button
				v-if="!editMode && tenant && !tenant.deleted"
				primary
				icon="pencilAlt"
				@click="edit()"
			>
				Edit mode
			</tool-bar-button>
			<tool-bar-button
				v-if="editMode && tenant && !tenant.deleted"
				primary
				icon="save"
				:disabled="!hasStateChanged || saveInProgress"
				@click="save"
			>
				{{ saveInProgress ? "Saving..." : "Save tenant" }}
			</tool-bar-button>
			<tool-bar-button
				v-if="editMode && tenant && !tenant.deleted"
				:disabled="saveInProgress"
				primary
				icon="times"
				@click="cancel"
			>
				Cancel
			</tool-bar-button>
			<tool-bar-button
				v-if="!editMode && tenant && !tenant.deleted && mayUpdateTenant"
				class="updateButton"
				icon="history"
				:disabled="saveInProgress"
				@click="confirmationUpdateOpen"
			>
				Update
			</tool-bar-button>
			<confirmation-dialog
				ref="confirmationUpdate"
				ok-label="Yes"
				cancel-label="Cancel"
				no-close-on-esc
				no-close-on-outside-click
				no-top-right-cross
				@ok="update(true)"
				@cancel="update(false)"
			>
				<template #header>
					<div>
						Update tenant
					</div>
				</template>
				<template #body>
					<div>
						Are you sure you want to update the tenant?
					</div>
				</template>
			</confirmation-dialog>
			<DeleteTenant
				v-if="tenant && !tenant.deleted"
				:disabled="checkStatus(tenant.infrastructure_status) || saveInProgress"
				:icon-only="false"
				:tenant-name="tenant.name || ''"
				@delete-tenant="onDeleteTenant"
			/>
			<tool-bar-button
				:disabled="saveInProgress"
				data-cy="go-to-tenant-users"
				icon="user"
				@click="goToUsers"
			>
				Users
			</tool-bar-button>
			<tool-bar-button
				:disabled="saveInProgress"
				data-cy="go-to-tenant-managers"
				icon="user"
				@click="goToManagers"
			>
				Managers
			</tool-bar-button>
		</div>
		<div class="right">
			<tool-bar-button
				data-cy="reload"
				icon="syncAlt"
				@click="reload"
			>
				Reload
			</tool-bar-button>
			<tool-bar-button
				:disabled="saveInProgress"
				@click="goHome"
			>
				Go back to tenants list
			</tool-bar-button>
		</div>
	</app-toolbar>
</template>

<script>

import {
	AppToolbar,
	ConfirmationDialog,
	ToolBarButton
} from "@abas/elements";

import STORE_CONSTANTS from "@/store/constants";

import { getTenantLink } from "@/utils/tenant";

import DeleteTenant from "./DeleteTenant.vue";
import DeleteTenantMixin from "./DeleteTenant.mixin";

import EDIT_TENANT_CONSTANTS from "./EditTenant.constants";
import EditTenantMixin from "./EditTenant.mixin";

export default {
	name: "TenantPageToolbar",
	components: {
		AppToolbar,
		ConfirmationDialog,
		DeleteTenant,
		ToolBarButton,
	},
	mixins: [ DeleteTenantMixin, EditTenantMixin ],
	computed: {
		tenantId() {
			return this.$route.params.id;
		},
		tenant() {
			return this.$store.getters[STORE_CONSTANTS.tenants.getters.getSelectedTenantName()];
		},
		userPermissions() {
			return this.$store.getters[STORE_CONSTANTS.permissions.getters.getQueryName()] || [];
		},
		mayUpdateTenant() {
			return this.userPermissions.includes("update-tenant");
		},
		hasStateChanged() {
			return this.$store.getters[STORE_CONSTANTS.editTenant.getters.queryTenantDataChangedName()];
		},
		editMode() {
			return this.$store.getters[STORE_CONSTANTS.editTenant.getters.getQueryEditStateName()];
		},
		saveInProgress() {
			return this.$store.getters[STORE_CONSTANTS.editTenant.getters.getQuerySaveInProgress()];
		}
	},
	unmounted() {
		if (this.editMode) {
			this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQueryEditStateName());
		}
	},
	methods: {
		edit() {
			this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQueryEditStateName());
		},
		cancel() {
			this.$store.dispatch(STORE_CONSTANTS.editTenant.getters.getQueryEditStateName());
		},
		checkStatus(tenantStatus) {
			return tenantStatus === "DELETE_IN_PROGRESS" || tenantStatus === "CREATE_IN_PROGRESS";
		},
		confirmationUpdateOpen() {
			this.$refs.confirmationUpdate.open();
		},
		goHome() {
			this.$router.push("/");
		},
		goToManagers() {
			this.$router.push(`/tenant/${this.tenantId}/managers`);
		},
		goToUsers() {
			const url = `${getTenantLink(this.tenant.name)}/admin/v2/?adminManagement=true`;
			window.open(url);
		},
		async onDeleteTenant(retainAuth0) {
			try {
				await this.deleteTenant(this.tenantId, retainAuth0);
				await this.$store.dispatch(STORE_CONSTANTS.tenants.getters.getQueryName());
				this.goHome();
			} catch (e) {
				this.$error("Cannot delete tenant. Please try again later.")
			}
		},
		reload() {
			this.$emitter.emit(EDIT_TENANT_CONSTANTS.events.reloadTenant);
		},
		save() {
			this.$emitter.emit(EDIT_TENANT_CONSTANTS.events.saveTenant);
		},
		update(confirmed) {
			this.$refs.confirmationUpdate.close();
			if (confirmed) {
				this.$emitter.emit(EDIT_TENANT_CONSTANTS.events.updateTenant);
			}
		}
	},
};
</script>

<style lang="scss" scoped>

.left {
	display: flex;
	flex-grow: 1;
}

.right {
	display: flex;
	flex-grow: 0;
}

:deep(.updateButton) .fa-history {
	transform: scaleX(-1);
}
</style>
